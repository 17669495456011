<template>
    <div class="reset">
        <div class="entete">
            <h1 class="titre">Changement du mot de passe</h1>
        </div>
        <div class="carte-arrondie reset">
            <v-card-title></v-card-title>
            <div class="v-card-content">
                <v-card-text>
                    <v-form @submit.prevent="changePassword" v-model="formValid">
                        <div class="pb-3">
                            <v-text-field
                                v-model="email"
                                label="Adresse Email"
                                name="entrez votre email"
                                :prepend-icon="mdiAccount"
                                type="text"
                                :rules="[rules.valeurrequise, rules.emailvalide]"
                            ></v-text-field>
                            <v-text-field
                                v-model="password"
                                label="Entrez le mot de passe"
                                :prepend-icon="mdiLock"
                                :type="show ? 'text' : 'password'"
                                :rules="[rules.valeurrequise, rules.min, rules.complexe]"
                            >
                                <template v-slot:append>
                                    <v-icon v-if="show" @click="show = !show" tabindex="-1">{{ mdiEye }}</v-icon>
                                    <v-icon v-else @click="show = !show" tabindex="-1">{{ mdiEyeOff }}</v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                v-model="rePassword"
                                label="Re-entrez le mot de passe"
                                :prepend-icon="mdiLock"
                                :type="show ? 'text' : 'password'"
                                :rules="[rules.valeurrequise, rules.min, rules.complexe, correspondance]"
                            ></v-text-field>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center">
                        <btn-theme type="submit" @click="changePassword" @:disabled="!formValid">Redéfinir mot de passe</btn-theme>
                    </v-row>
                </v-card-actions>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../api/api';
import {mapActions} from "vuex";
import BtnTheme from "../components/theme/BtnTheme";
import { mdiAccount, mdiLock, mdiEye, mdiEyeOff } from "@mdi/js";


export default {
    name: "Login",
    components: {BtnTheme},
    data () {
        return {
            show: false,
            token: '',
            email: '',
            password: '',
            rePassword: '',
            rules: {
              valeurrequise: value => !!value || "Requis",
              min: v => v.length >= 8 || "Minimum 8 caractères",
              emailvalide: v => /.+@.+/.test(v) || 'L\'email doit être valide',
              complexe: v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{8,}$/.test(v) || 'Le mot de passe doit comporter des majuscules, des minuscules et des chiffres',
            },
            formValid: true,
            resetURI:  process.env.VUE_APP_API_URL + '/reinit',
            erreursPossibles: {
                'passwords.user': 'Adresse email utilisateur invalide.',
                'passwords.token': 'Jeton de réinitialisation invalide.<br/>Rappel, le lien de réinitialisation n\'est valide que pendant 60 minutes.'
            },
            mdiAccount,
            mdiLock,
            mdiEye,
            mdiEyeOff
        }
    },
    computed: {
      correspondance() {
        return this.password === this.rePassword || "Le mot de passe et sa confirmation ne correspondent pas.";
      }
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        changePassword() {
            if (!this.formValid) return;
            // routine d'envoi du mot de passe sur api.
            let data = new FormData;
            data.append('token', this.token);
            data.append('email', this.email);
            data.append('password', this.password);
            data.append('password_confirmation', this.rePassword);
            return Api.post(this.resetURI, data).then(() => {
                this.login({
                    email: this.email,
                    password: this.password
                }, '/')
                    .then(() => {
                        this.$store.commit('addSnackMessage', {msg: 'Mot de passe réinitilisé avec succès.', color: 'success',});
                    })
                    .catch(this.traiteErreurs);
            }).catch(this.traiteErreurs);
        },
        traiteErreurs(err) {
            let msg = Api.getMessageFrom(err);
            if (this.erreursPossibles[msg] !== undefined)
                msg = this.erreursPossibles[msg];
            else
                msg = 'Erreur lors de la réinitialisation, merci de contacter notre service client.'
            if (msg)
                this.$store.commit('addSnackMessage', {msg: msg});
        }
    },
    mounted () {
        this.email = this.$route.query.email;
        this.token = this.$route.params.token;
    }
}
</script>

<style scoped lang="scss">
.reset {
    & .carte-arrondie {
        flex-direction:column;
        max-width:400px;
    }
}
.v-card-content {
    padding: 0 3em;
}
.v-card__title {
    display: block !important;
    text-align: center !important;
}
</style>